import SimpleCrypto from "simple-crypto-js";

const key =
  "59F7806F6F9BB6D9C506B1F5701D34F8E3128BBF29675A635E54DBA03A4C489C972EAB341A0AC05E5F6D354EDA0ECC8581E547C7AC7EE6E23836FF70EE10252A";
const enc = new SimpleCrypto(key);

function isTokenValid() {
  const cryptedToken = localStorage.getItem("lk2Token");
  if (!cryptedToken) {
    return false;
  }
  let tokenStr;

  try {
    tokenStr = enc.decrypt(cryptedToken);
  } catch (err) {
    console.log(err);
    return false;
  }

  if (!tokenStr) {
    return false;
  }

  const token = JSON.parse(JSON.stringify(tokenStr));
  return !!token.access_token;
}

function getTokenData() {
  const cryptedToken = localStorage.getItem("lk2Token");
  if (!cryptedToken) {
    return null;
  }
  return JSON.parse(JSON.stringify(enc.decrypt(cryptedToken)));
}

async function setToken(token) {
  const uri = localStorage.getItem("uri");
  const headers = {
    Authorization: `Bearer ${token.access_token}`,
  };

  let result = { role: getTokenData()?.role };
  if (!result.role) {
    try {
      const roleRequest = await fetch(`${uri}/role`, {
        headers,
        mode: "cors",
      });
      result = await roleRequest.json();
    } catch (err) {
      console.log(err);
      return;
    }
  }
  const credObj = { ...token, ...result };
  const obj = enc.encrypt(credObj, true);
  localStorage.setItem("lk2Token", obj);
}

export { getTokenData, isTokenValid, setToken };
