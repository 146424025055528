import * as actionType from "../actions/actionTypes";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

const initialState = {
  auth: false,
  timeExpired: false,
  tokenTimeRefresh: 0,
  rememberMe: false,
  loginStatus: null,
  spinner: null,
  token: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        spinner: false,
        loginStatus: "Login success",
      };
    case actionType.LOGIN_PROCCESS:
    case actionType.START_HTTP:
      return {
        ...state,
        spinner: true,
      };
    case actionType.END_HTTP:
      return {
        ...state,
        spinner: false,
      };
    case actionType.LOGIN_ERROR:
      localStorage.removeItem("remember");
      return {
        ...state,
        auth: false,
        spinner: false,
        loginStatus: action.error.message,
      };
    case actionType.CLOSE_MODAL:
      return {
        ...state,
        timeExpired: false,
      };
    case actionType.LOGOUT:
      return {
        ...state,
        timeExpired: action.data,
        auth: false,
        loginStatus: "Logout",
      };
    case actionType.SAVE_TOKEN:
    case actionType.REFRESH_TOKEN:
      localStorage.removeItem("userData");
      localStorage.setItem("userData", JSON.stringify(action.data));
      return {
        ...state,
        token: {
          item: action.data.token,
          expire: action.data.expire,
        },
      };
    case actionType.SET_TOKEN_TIME:
      return {
        ...state,
        tokenTimeRefresh: action.data,
      };
    case actionType.GET_CONFIG:
      localStorage.setItem("uri", action.data);
      return state;
    default:
      return state;
  }
};

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
