export const LOGIN = "LOGIN";
export const LOGIN_PROCCESS = "LOGIN_PROCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const SAVE_TOKEN = "SAVE_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_TOKEN_TIME = "SET_TOKEN_TIME";

export const HTTP_GET = "HTTP_GET";
export const HTTP_POST = "HTTP_POST";
export const GET_HTTP_DATA = "HTTP_DATA";
export const START_HTTP = "START_HTTP";
export const END_HTTP = "END_HTTP";

export const CLOSE_MODAL = "CLOSE_MODAL";
export const GET_CONFIG = "GET_CONFIG";
