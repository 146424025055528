import { getTokenData, setToken } from "../utils/token";
import * as actionTypes from "./actionTypes";
import { logout } from "./login";

export const saveToken = token => ({
  type: actionTypes.SAVE_TOKEN,
  data: token,
});

export const setTokenTime = data => ({
  type: actionTypes.SET_TOKEN_TIME,
  data,
});

export const refreshToken = (path, method, body, prevToken) => {
  const token = getTokenData().refresh_token;
  const uri = localStorage.getItem("uri");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return dispatch => {
    return fetch(`${uri}/refreshToken`, {
      headers: headers,
    })
      .then(result => result?.json())
      .then(result => {
        if (result?.access_token) {
          setToken(result);
          return result;
        } else {
          dispatch(logout());
        }
      })
      .then(result => {
        return fetch(`${uri}/${path}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${result?.access_token}`,
          },
          method: method,
          body: JSON.stringify(body),
          mode: "cors",
        })
          .then(result => result?.json())
          .then(result => {
            return result;
          })
          .catch(err => {
            console.log(err);
            dispatch(logout());
          });
      });
  };
};

export const refreshTokenState = token => ({
  type: actionTypes.REFRESH_TOKEN,
  data: token,
});
