import store from "../reducers";
import { getTokenData } from "../utils/token";
import * as actionTypes from "./actionTypes";
import { logout } from "./login";
import { refreshToken } from "./token";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

let uri;

export const getHttpData = data => ({
  type: actionTypes.GET_HTTP_DATA,
  data: data,
});

export const httpStart = () => ({
  type: actionTypes.START_HTTP,
});

export const httpEnd = () => ({
  type: actionTypes.END_HTTP,
});

function getConfigs() {
  return localStorage.getItem("uri");
}

export const generalGetExcel = (path, method, body) => {
  if (!uri) {
    uri = getConfigs();
  }

  const userData = getTokenData();

  if (!userData) {
    store.dispatch(logout());
    return;
  }
  headers.Authorization = `Bearer ${userData.access_token}`;
  let req = {};

  req = fetch(`${uri}/${path}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getTokenData()?.access_token}`,
    },
    method: method,
    body: JSON.stringify(body),
    mode: "cors",
  });

  return async dispatch => {
    dispatch(httpStart());
    return req
      .then(result => {
        return result.blob();
      })
      .then(result => {
        if (result?.status === 403) {
          const data = dispatch(refreshToken(req));
          dispatch(httpEnd());
          return data;
        } else if (result?.code === 401) {
          dispatch(logout());
          dispatch(httpEnd());
          return;
        }
        dispatch(httpEnd());
        return result;
      })
      .catch(err => {
        dispatch(httpEnd());
      });
  };
};

export const generalReq = (path, method, body) => {
  if (!uri) {
    uri = getConfigs();
  }

  const userData = getTokenData();

  if (!userData) {
    store.dispatch(logout());
    return;
  }
  headers.Authorization = `Bearer ${getTokenData()?.access_token}`;
  let req = {};

  if (method === "GET") {
    req = fetch(`${uri}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getTokenData()?.access_token}`,
      },
      mode: "cors",
    });
  } else {
    req = fetch(`${uri}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getTokenData()?.access_token}`,
      },
      method: method,
      body: JSON.stringify(body),
      mode: "cors",
    });
  }

  return async dispatch => {
    dispatch(httpStart());
    return req
      .then(result => {
        if (result?.status === 403) {
          const data = dispatch(refreshToken(path, method, body, getTokenData()?.access_token));
          dispatch(httpEnd());
          return data;
        } else if (result?.status === 401) {
          dispatch(logout());
          dispatch(httpEnd());
          return;
        }
        return result?.json();
      })
      .then(result => {
        dispatch(httpEnd());
        return result;
      })
      .catch(err => {
        console.log(err);
        dispatch(logout());
        dispatch(httpEnd());
      });
  };
};

export const generalReqDownload = (path, method, body) => {
  if (!uri) {
    uri = getConfigs();
  }

  const userData = getTokenData();

  if (!userData) {
    store.dispatch(logout());
    return;
  }
  headers.Authorization = `Bearer ${userData?.access_token}`;
  let req = {};

  if (method === "GET") {
    req = fetch(`${uri}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getTokenData()?.access_token}`,
      },
      mode: "cors",
    });
  } else {
    req = fetch(`${uri}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getTokenData()?.access_token}`,
      },
      method: method,
      body: JSON.stringify(body),
      mode: "cors",
    });
  }

  return async dispatch => {
    dispatch(httpStart());
    return req
      .then(result => {
        return result?.arrayBuffer();
      })
      .then(result => {
        if (result?.status === 403) {
          const data = dispatch(refreshToken(req));
          dispatch(httpEnd());
          return data;
        } else if (result?.code === 401) {
          dispatch(logout());
          dispatch(httpEnd());
          return;
        }
        dispatch(httpEnd());
        return result;
      })
      .catch(err => {
        dispatch(httpEnd());
      });
  };
};

export const generalReqMultipart = (path, method, body) => {
  if (!uri) {
    uri = getConfigs();
  }

  const userData = getTokenData();

  if (!userData) {
    store.dispatch(logout());
    return;
  }
  const headers = {
    // 'Content-Type': 'multipart/form-data;boundary=------WebKitFormBoundary7MA4YWxkTrZu0gW',
  };
  headers.Authorization = `Bearer ${userData.access_token}`;

  let req = {};

  if (method === "GET") {
    req = fetch(`${uri}/${path}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getTokenData()?.access_token}`,
      },
      mode: "cors",
    });
  } else {
    req = fetch(`${uri}/${path}`, {
      headers: headers,
      method: method,
      body: body,
      mode: "cors",
    });
  }

  return async dispatch => {
    dispatch(httpStart());
    return req
      .then(result => {
        return result?.json();
      })
      .then(result => {
        if (result?.status === 403) {
          const data = dispatch(refreshToken(req));
          dispatch(httpEnd());
          return data;
        } else if (result?.code === 401) {
          dispatch(logout());
          dispatch(httpEnd());
          return;
        }
        dispatch(httpEnd());
        return result;
      })
      .catch(err => {
        console.log(err);
        dispatch(httpEnd());
      });
  };
};

export const httpGet = path => {
  return generalReq(path, "GET");
};

export const httpPost = (path, body) => {
  return generalReq(path, "POST", body);
};
export const httpExcel = (path, method, body) => {
  return generalGetExcel(path, method, body);
};
export const httpPut = (path, body) => {
  return generalReq(path, "PUT", body);
};

export const httpPostMultipart = (path, body) => {
  return generalReqMultipart(path, "POST", body);
};

export const httpGetDownload = path => {
  return generalReqDownload(path, "GET");
};

export const httpDelete = (path, body) => {
  return generalReq(path, "DELETE", body);
};
