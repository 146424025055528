import { setToken } from "../utils/token";
import * as actionType from "./actionTypes";

// export const rememberUser = () => {
//   return dispatch => {
//     timer = setInterval(() => {
//       dispatch(token?.refreshToken());
//     }, 15 * 1000);
//   };
// };
let uri;
export const login = (username, password) => {
  return async dispatch => {
    dispatch(loginProccess());
    return fetch("config.json")
      .then(res => res?.json())
      .then(res => {
        uri = res?.backendUri;
        localStorage.setItem("uri", uri);
        return fetch(`${uri}/login`, {
          method: "POST",
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        })
          .then(result => result?.json())
          .then(result => {
            if (result?.message && !result?.token) {
              dispatch(
                loginError({
                  message: "Неверный логин или пароль!",
                }),
              );
              return false;
            } else {
              try {
                setToken(result)
                  .then(() => {
                    localStorage.setItem("username", result.userId);
                    dispatch(loginSuccess());
                    return true;
                  })
                  .catch(() => false);
              } catch (err) {
                dispatch(loginError({ message: err }));
                return false;
              }
            }
          })
          .catch(err =>
            dispatch(
              loginError({
                message: err.toString(),
              }),
            ),
          );
      });
  };
};

export const loginProccess = () => ({
  type: actionType.LOGIN_PROCCESS,
});

export const loginSuccess = token => ({
  type: actionType.LOGIN_SUCCESS,
  data: token,
});

export const loginError = error => ({
  type: actionType.LOGIN_ERROR,
  error,
});

const backLogout = async () => {
  let res = await fetch(`${uri}/quit`, {
    method: "POST",
  });
  return res;
};

export const logout = (showExpireModal = false) => {
  localStorage.removeItem("lk2Token");
  backLogout();
  return {
    type: actionType.LOGOUT,
    data: showExpireModal,
  };
};

export const closePopup = () => ({
  type: actionType.CLOSE_MODAL,
  data: false,
});
