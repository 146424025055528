import { library } from "@fortawesome/fontawesome-svg-core";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import store from "./reducers";
import * as Icons from "./sharedStyles/Icons";

library.add(Icons);

const theme = createTheme({
  props: {
    MuiSelect: {
      MenuProps: {
        style: {
          maxHeight: 400,
          maxWidth: 400,
        },
      },
    },
  },
});

const APP = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<APP />, document.getElementById("root"));
